var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"wrapper coloured-bg"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"index-header"},[_c('div',{staticClass:"index-header-left"},[_c('div',{staticClass:"index-header-left-text"},[_c('p',{staticClass:"promo-tip ff_semibold",domProps:{"textContent":_vm._s(_vm.t('header.promo_tip'))}}),_vm._v(" "),_c('h1',{staticClass:"ff_bold header",domProps:{"textContent":_vm._s(_vm.t('header.title'))}}),_vm._v(" "),_c('ul',{staticClass:"points-ul"},[_c('li',{domProps:{"textContent":_vm._s(_vm.t('header.points[0]'))}}),_vm._v(" "),_c('li',{domProps:{"textContent":_vm._s(_vm.t('header.points[1]'))}}),_vm._v(" "),_c('li',[_c('i18n',{attrs:{"path":_vm.tPath('header.points[2].template'),"tag":"span"},scopedSlots:_vm._u([{key:"limits",fn:function(){return [_c('nuxt-link',{staticClass:"point-link",attrs:{"to":{
                        path: '/bitcoin-debit-card',
                        hash: 'pricing-screen',
                      }},domProps:{"textContent":_vm._s(_vm.t('header.points[2].limits'))}})]},proxy:true}])})],1),_vm._v(" "),_c('li',{domProps:{"textContent":_vm._s(_vm.t('header.points[3]'))}})])]),_vm._v(" "),_c('div',{staticClass:"apps-wrapper"},[_c('a',{staticClass:"header-main-button apps-wrapper__button",attrs:{"href":_vm.orderNowLink,"target":"_blank"},on:{"click":function($event){return _vm.$track('Clicked on order now', {
                  name: 'order now',
                  place: 'start site',
                })}}},[_vm._v("\n              "+_vm._s(_vm.t('header.order_now'))+"\n            ")]),_vm._v(" "),_c('a',{attrs:{"href":_vm.links['app-store'],"target":"_blank"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'Download on the AppStore',
                  place: 'start site',
                })}}},[_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged('app-store-icon')),expression:"visibilityChanged('app-store-icon')"}]},[(_vm.elements['app-store-icon'])?_c('app-store-icon'):_vm._e()],1)]),_vm._v(" "),_c('a',{attrs:{"href":_vm.links['google-play'],"target":"_blank"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'Get it on Google Play',
                  place: 'start site',
                })}}},[_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged('google-play-icon')),expression:"visibilityChanged('google-play-icon')"}]},[(_vm.elements['google-play-icon'])?_c('google-play-icon'):_vm._e()],1)])])]),_vm._v(" "),_c('div',{staticClass:"index-header-illustration-wrap"},[_c('IndexIllustration')],1)])])]),_vm._v(" "),_c('div',{staticClass:"coins"},[_c('CoinsTable',{attrs:{"items":_vm.coins,"loading":_vm.coinsLoading}}),_vm._v(" "),_c('nuxt-link',{staticClass:"more-button",attrs:{"to":"/buy-cryptocurrency"}},[_vm._v("\n      "+_vm._s(_vm.t('more'))+"\n    ")])],1),_vm._v(" "),_c('article',{staticClass:"article"},[_c('section',{staticClass:"main-section"},[_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged('buy-with-card-icon')),expression:"visibilityChanged('buy-with-card-icon')"}]},[(_vm.elements['buy-with-card-icon'])?_c('buy-with-card-icon',{staticClass:"icon"}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-container"},[_c('h2',[_vm._v("\n          "+_vm._s(_vm.t('buy_bitcoins_with_your_bank_card'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"t2"},[_vm._v("\n          "+_vm._s(_vm.t('easy_access'))+"\n        ")]),_vm._v(" "),_c('a',{staticClass:"btn invert",attrs:{"href":_vm.links['sign-up']},on:{"click":function($event){return _vm.$track('Clicked button', {
              name: 'buy bitcoins',
              place: 'start site',
            })}}},[_vm._v("\n          "+_vm._s(_vm.t('buy_bitcoins'))+"\n        ")])])]),_vm._v(" "),_c('section',{staticClass:"main-section change-order"},[_c('div',{staticClass:"text-container"},[_c('h2',[_vm._v("\n          "+_vm._s(_vm.t('use_the_cryptopay_card'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"t2"},[_vm._v("\n          "+_vm._s(_vm.t('cryptopay_card_links_bitcoin_with_finances'))+"\n        ")]),_vm._v(" "),_c('a',{staticClass:"btn invert",attrs:{"href":_vm.links['sign-up']},on:{"click":function($event){return _vm.$track('Clicked button', {
              name: 'order a card',
              place: 'start site',
            })}}},[_vm._v("\n          "+_vm._s(_vm.t('order_card'))+"\n        ")])]),_vm._v(" "),_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged('use-card-icon')),expression:"visibilityChanged('use-card-icon')"}]},[(_vm.elements['use-card-icon'])?_c('use-card-icon',{staticClass:"icon"}):_vm._e()],1)]),_vm._v(" "),_c('section',{staticClass:"main-section"},[_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged('exchange-currencies-icon')),expression:"visibilityChanged('exchange-currencies-icon')"}]},[(_vm.elements['exchange-currencies-icon'])?_c('exchange-currencies-icon',{staticClass:"icon"}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-container"},[_c('h2',[_vm._v(_vm._s(_vm.t('exchange_currencies')))]),_vm._v(" "),_c('p',{staticClass:"t2"},[_vm._v("\n          "+_vm._s(_vm.t('hold_bitcoins_securely'))+"\n        ")]),_vm._v(" "),_c('a',{staticClass:"btn invert",attrs:{"href":_vm.links['sign-up']},on:{"click":function($event){return _vm.$track('Clicked button', {
              name: 'exchange money now',
              place: 'start site',
            })}}},[_vm._v("\n          "+_vm._s(_vm.t('exchange_money_now'))+"\n        ")])])]),_vm._v(" "),_c('section',{staticClass:"main-section change-order"},[_c('div',{staticClass:"text-container"},[_c('h2',[_vm._v("\n          "+_vm._s(_vm.t('say_goodbye_to_hidden_fees'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"t2"},[_vm._v("\n          "+_vm._s(_vm.t('cryptocurrency_has_transaction_fee'))+"\n        ")]),_vm._v(" "),_c('a',{staticClass:"btn invert",attrs:{"href":_vm.links['sign-up']},on:{"click":function($event){return _vm.$track('Clicked button', {
              name: 'get a free bitcoin wallet',
              place: 'start site',
            })}}},[_vm._v("\n          "+_vm._s(_vm.t('get_a_free_bitcoin_wallet'))+"\n        ")])]),_vm._v(" "),_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged('hidden-fees-icon')),expression:"visibilityChanged('hidden-fees-icon')"}]},[(_vm.elements['hidden-fees-icon'])?_c('hidden-fees-icon',{staticClass:"icon"}):_vm._e()],1)]),_vm._v(" "),_c('section',{staticClass:"main-section"},[_c('span',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged('splendid-support-icon')),expression:"visibilityChanged('splendid-support-icon')"}]},[(_vm.elements['splendid-support-icon'])?_c('splendid-support-icon',{staticClass:"icon"}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-container"},[_c('h2',[_vm._v("\n          "+_vm._s(_vm.t('splendid_support'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"t2"},[_vm._v("\n          "+_vm._s(_vm.t('type_of_conversation'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"support-numbers-wrapper"},[(_vm.prepaidCards)?_c('div',{staticClass:"support-numbers-item"},[_c('span',{staticClass:"h4",domProps:{"innerHTML":_vm._s(_vm.prepaidCards)}}),_vm._v(" "),_c('span',{staticClass:"t4"},[_vm._v(_vm._s(_vm.t('prepaid_cards_issued')))])]):_vm._e(),_vm._v(" "),(_vm.usersCount)?_c('div',{staticClass:"support-numbers-item"},[_c('span',{staticClass:"h4",domProps:{"innerHTML":_vm._s(_vm.usersCount)}}),_vm._v(" "),_c('span',{staticClass:"t4"},[_vm._v(_vm._s(_vm.t('active_users')))])]):_vm._e(),_vm._v(" "),(_vm.lastMonthTransactionsCount)?_c('div',{staticClass:"support-numbers-item"},[_c('span',{staticClass:"h4",domProps:{"innerHTML":_vm._s(_vm.lastMonthTransactionsCount)}}),_vm._v(" "),_c('span',{staticClass:"t4"},[_vm._v("\n              "+_vm._s(_vm.t('transactions_processed_per_month'))+"\n            ")])]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }