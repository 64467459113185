import { render, staticRenderFns } from "./UseCardIcon.vue?vue&type=template&id=6ac7a211&"
import script from "./UseCardIcon.vue?vue&type=script&lang=js&"
export * from "./UseCardIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/var/www/html/components/svg-icons/SvgIcon.vue').default})
