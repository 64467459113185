//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgIcon from './SvgIcon.vue'
export default {
  name: 'GooglePlayIcon',
  components: {
    SvgIcon,
  },
  data () {
    return {
      options: {
        width: '32',
        height: '32',
        viewBox: '0 0 32 32',
        fill: 'none',
      },
    }
  },
}
