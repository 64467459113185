//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'
import { mapState } from 'vuex'
import IndexIllustration from './components/index-illustration'
import UseCardIcon from '~/components/svg-icons/UseCardIcon'
import GooglePlayIcon from '~/components/svg-icons/GooglePlayIcon'
import AppStoreIcon from '~/components/svg-icons/AppStoreIcon'
import ExchangeCurrenciesIcon from '~/components/svg-icons/ExchangeCurrenciesIcon'
import HiddenFeesIcon from '~/components/svg-icons/HiddenFeesIcon'
import SplendidSupportIcon from '~/components/svg-icons/SplendidSupportIcon'
import BuyWithCardIcon from '~/components/svg-icons/BuyWithCardIcon'
import CoinsTable from '~/components/buy-crypto/Table'
import links from '~/helpers/links'
import { page } from '~/plugins/segment'

export default {
  components: {
    IndexIllustration,
    UseCardIcon,
    GooglePlayIcon,
    AppStoreIcon,
    ExchangeCurrenciesIcon,
    HiddenFeesIcon,
    SplendidSupportIcon,
    BuyWithCardIcon,
    CoinsTable,
  },
  directives: {
    ObserveVisibility,
  },
  data: () => ({
    elements: {},
    links,
    lastMonthTransactionsCount: null,
    usersCount: null,
    prepaidCards: null,
    requestCompleted: false,
    orderNowLink: '/card_orders/new',
  }),
  computed: {
    ...mapState({
      coinsLoading: state => state.coins.assets.loading,
      coins: state => state.coins.assets.data.slice(0, 5),
    }),
  },
  head: () => ({
    title: 'Buy Bitcoins | Bitcoin Wallet | Debit Card',

    meta: [
      {
        hid: 'keywords',
        name: 'keywords',
        content:
            'bitcoins, bitcoin wallet, online bitcoin wallet, cryptocurrency',
      },
      {
        hid: 'description',
        name: 'description',
        content:
            'Buy bitcoins in the Europe and transfer them globally from your bitcoin wallet without using a bank. Use Cryptopay debit card to easily withdraw funds',
      },
    ],
  }),

  mounted () {
    page('Buy Bitcoins | Bitcoin Wallet | Debit Card', 'start')
    this.$store.dispatch('coins/loadAssets')
    /*
    temporary disabled

    this.$axios.get('/api/web/pages').then((res) => {
      const data = res.data.result || res.data
      this.lastMonthTransactionsCount = this.getLocalNumber(
        data.last_month_transactions_count,
        3,
        'en-US',
      )
      this.usersCount = this.getLocalNumber(data.users_count, 3, 'en-US')
      this.prepaidCards = this.getLocalNumber(data.cards_count, 3, 'en-US')
    })
    */
  },

  methods: {
    visibilityChanged (key) {
      return (bool) => {
        Vue.set(this.elements, key, bool)
      }
    },
    getLocalNumber (number, getDecimal = 3, format = undefined) {
      if (!number) {
        return
      }
      const n = typeof number === 'string' ? +number : number
      return n.toLocaleString(format, { maximumFractionDigits: getDecimal })
    },
    tPath (s) {
      return 'index.' + s
    },
    t (key, options) {
      return this.$t(this.tPath(key), options)
    },
  },
}
